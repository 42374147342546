import React from "react"
import { Row, Container, Col } from 'react-bootstrap'
import moment from 'moment'

import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from '../components/image/image'

const AboutMePage = () => {

  const changeNameImg = "";

  return <Layout>
    <SEO title="Home"  />
    <Container className="mt-4">
        <Row>
          <Col lg="7" md="12">
              <Row className="mb-5">
                  <Col lg="3" className="text-center">
                      <div className="avatar mt-0"></div>
                      <a href="#hireme" className="btn btn-yellow">Hire me</a>
                  </Col>
                  <Col lg="9">
                      <div className="pre-name">
                          <h5>Hello</h5>
                          <hr></hr>
                      </div>
                      <h3 className="mario-pre">It’s me</h3>
                      <div className="mario-img">
                        <Image style={{ maxHeight: "40px" }} fileName="changename.webp" alt="name joke"></Image>
                      </div>
                      <h6>Tech Lead & Code Lover Looking For New Challenges</h6>
                      <p>
                       With more than 6 years of experience working with PHP, Java and Angular. I have the capability to manage teams, face complex situations, through analysis, evaluation and decision making. I am a versatile person who seeks new challenges to improve as a professional
                      </p>
                  </Col>
              </Row>
              <Row>
                  <Col md="12">
                      <h5>Professional experience</h5>
                  </Col>
              </Row>
              <Row >
                  <Col md="12" className="info-profession">
                      <div className="info-title">Q&R Solutions</div>
                      <div className="info-subtitle">Tech Lead - from Oct 2019 to the present</div>
                      <div className="info-content">
                          <p>
                           -	I am responsible for a team of 5 people bringing the use of SCRUM to the culture of the company, which was adopted by other areas. 
                           </p>
                           <p>
                           -	I streamlined the delivery of functionalities and error tickets were reduced by more than 50% thanks to the implementation of testing, continuous integration and use of TDD and BDD. 
                           </p>
                           <p>
                            -	I led the migration of 100% of the infrastructure to a microservices system allowing control availability, over versioning and error management. 
                            </p>
                            <p>
                            -	I designed the architecture and supervised the development of a web system with a presence in Argentina and Uruguay that has a traffic of 15,000 concurrent users and it was possible to increase the rate of new users by more than 125%                         
                          </p>
                        <br></br>
                        <p><strong>Tech-stack</strong>: Angular, Typescript, Java, PHP, AWS, Docker, SCRUM, Bitbucket, Jenkins, Springboot, MongoDB, PostgreSql</p>
                      </div>
                  </Col>
              </Row>
              <Row >
                  <Col md="12" className="info-profession">
                      <div className="info-title">Hospital Universitario Austral</div>
                      <div className="info-subtitle">Full Stack Developer - from Jul 2018 to Oct 2019</div>
                      <div className="info-content">
                        <p>
                        -	I developed and maintained the applications used internally ensuring the quality and good practices of the code.
                        </p>
                        <p>
                        -	I was part of the team that defined the architecture bases in FrontEnd projects using ReactJs which is used in more than 3 productive applications.
                        </p>
                        <p>
                        -	I developed utility tools used by the team to minimize the times in daily and redundant tasks based on Bash scripts.
                        </p>
                        <br></br>
                        <p><strong>Tech-stack</strong>: Typescript, .Net Core, SqlServer, ReactJs, VueJs, JQuery, EntityFramework Core, SCRUM</p>
                      </div>
                  </Col>
              </Row>
              <Row>
                  <Col md="12" className="info-profession">
                      <div className="info-title">FosterJobs</div>
                      <div className="info-subtitle">Full Stack Developer - from Feb 2015 to Jun 2018</div>
                      <div className="info-content">
                        <p>
                        -	I made the decision to use AngularJs together with MaterialUI on the platform to improve the user experience, redesigning 100% of the FrontEnd based on the brand manual, capturing a greater volume of users.
                        </p>
                        <p>
                        -	I was the IT contact of the clients and took part in various meetings, proposed new functionalities and sold the product.
                        </p>
                        <p>
                        -	I designed and proposed the technologies used in more than 3 solutions for events of companies and universities with national impact.  
                        </p>
                        <br></br>
                        <p><strong>Tech-stack</strong>: Angular, PHP, PostgreSql, AWS, Java, JQuery, Laravel</p>
                      
                      </div>
                  </Col>
              </Row>
              <Row className="mt-5">
                  <Col md="12">
                      <h5>Education</h5>
                  </Col>
              </Row>
              <Row >
                  <Col md="12" className="info-profession">
                      <div className="info-title">Lic. en Sistemas de información</div>
                      <div className="info-subtitle">Universidad Nacional de Luján</div>
                  </Col>
              </Row>
              <Row>
                  <Col md="12" className="info-profession">
                      <div className="info-title">Analista Programador Universitario</div>
                      <div className="info-subtitle">Universidad Nacional de Luján</div>
                  </Col>
              </Row>
          </Col>
          <Col lg="5" xl={{ span: 4, offset: 1 }}  className="d-none d-lg-block">
              <div className="card">
                  <div className="card-body px-0 py-0">
                      <div className="background-info">
                          <div className="avatar-background"></div>
                      </div>
                      <Col md="12">
                        <h5 className="my-3 font-weight-bold">Some boring info about me</h5>
                      </Col>
                      <Row className="mt-2 mb-3">
                          <Col md="4" className="text-right info-title">Name</Col>
                          <Col md="8" >Fabian Alejandro Zamudio</Col>
                      </Row>
                      <Row className="mt-2 mb-3">
                          <Col md="4"  className="text-right info-title">Age</Col>
                          <Col md="8">{moment().diff('1989-12-22', 'years')} (22/12/1989)</Col>
                      </Row>
                      <Row className="mt-2 mb-3">
                          <Col md="4"  className="text-right info-title">Email</Col>
                          <Col md="8">fabian.zamudio.89@gmail.com</Col>
                      </Row>
                      <Row className="mt-2 mb-3">
                          <Col md="4"  className="text-right info-title">Address</Col>
                          <Col md="8">Córdoba Capital, Córdoba, Argentina</Col>
                      </Row>
                      <Row className="mt-2 mb-3">
                          <Col md="4"  className="text-right info-title">LinkedIn</Col>
                          <Col md="8"><a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/fabian-zamudio-dev">@fabian-zamudio-dev</a></Col>
                      </Row>
                      <Row className="mt-2 mb-3">
                          <Col md="4"  className="text-right info-title">GitHub</Col>
                          <Col md="8"><a target="_blank" rel="noreferrer" href="https://www.github.com/zamudio-fabian">@zamudio-fabian</a></Col>
                      </Row>
                      <Row className="mt-2 mb-3">
                          <Col md="4"  className="text-right info-title">Skype</Col>
                          <Col md="8"><a target="_blank" rel="noreferrer" href="https://join.skype.com/invite/spMAjyRDw0Dg">@zamudio_fabian</a></Col>
                      </Row>
                      <Row className="mt-2 mb-3">
                          <Col md="4"  className="text-right info-title">Twitter</Col>
                          <Col md="8" className=""><a target="_blank" rel="noreferrer" href="https://twitter.com/fabian_zamudio">@fabian_zamudio</a></Col>
                      </Row>
                  </div>
              </div>

              <Row>
                  <Col md="12" className="mt-5">
                      <h5>Skills</h5>
                      <h6>Time spend in projects</h6>
                  </Col>
                  <Col className="mt-3">
                      <h6>Angular</h6>
                      <div className="progress" style={{height: "8px;"}}>
                          <div className="progress-bar bg-success" role="progressbar" style={{width: "90%"}} 
                          aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                  </Col>
                  <Col md="12" className="mt-3">
                      <h6>ReactJs</h6>
                      <div className="progress" style={{height: "8px;"}}>
                          <div className="progress-bar bg-success" role="progressbar" style={{width: "55%"}} 
                          aria-valuenow="65" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                  </Col>
                  <Col md="12" className="mt-3">
                      <h6>VueJs</h6>
                      <div className="progress" style={{height: "8px;"}}>
                          <div className="progress-bar bg-success" role="progressbar" style={{width: "40%"}} 
                          aria-valuenow="40" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                  </Col>
                  <Col md="12" className="mt-3">
                      <h6>Springboot</h6>
                      <div className="progress" style={{height: "8px;"}}>
                          <div className="progress-bar bg-success" role="progressbar" style={{width: "72%"}} 
                          aria-valuenow="72" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                  </Col>
                  <Col md="12" className="mt-3">
                      <h6>C#</h6>
                      <div className="progress" style={{height: "8px;"}}>
                          <div className="progress-bar bg-success" role="progressbar" style={{width: "85%"}} 
                          aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                  </Col>
                  <Col md="12" className="mt-3">
                      <h6>Java</h6>
                      <div className="progress" style={{height: "8px;"}}>
                          <div className="progress-bar bg-success" role="progressbar" style={{width: "65%"}} 
                          aria-valuenow="65" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                  </Col>
                  <Col md="12" className="mt-3">
                      <h6>AWS</h6>
                      <div className="progress" style={{height: "8px;"}}>
                          <div className="progress-bar bg-success" role="progressbar" style={{width: "70%"}} 
                          aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                  </Col>
              </Row>
          </Col>
        </Row>
    </Container>
  </Layout>
}

export default AboutMePage
